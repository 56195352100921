import { Container, Flex, Stack } from '@chakra-ui/react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import type { GetStaticProps } from 'next/types';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { innerLinks } from '@/utils/constants/inner-links';
import BaseButton from '@/components/BaseButton';
import { colors } from '@/styles/theme/colors';
import { H3 } from '@/components/Headings';
import { TextDefault } from '@/components/Texts';
import { NextSeo } from 'next-seo';
import {
  META_DESCRIPTIONS,
  META_TITLES,
} from '@/utils/constants/meta-descriptions';

const NotFoundPage = () => {
  const { t } = useTranslation('main');

  return (
    <>
      <NextSeo
        title={META_TITLES.error404}
        description={META_DESCRIPTIONS.error404}
      />
      <Container maxW={'4xl'} py={12}>
        <Stack minH={'70dvh'} align={'center'} justify={'center'} gap={4}>
          <Image
            src={'/images/logos/dd-logo.svg'}
            width={128}
            height={50}
            alt='diment logo'
          />

          <Flex
            align={'center'}
            w='full'
            direction={{ base: 'column', md: 'row' }}
            justify={'center'}
            textAlign={'center'}
          >
            <Stack spacing={8}>
              <H3>404</H3>
              <TextDefault px={10}>{t('not-found.description')}</TextDefault>
              <Link href={innerLinks.main}>
                <BaseButton bg={colors.diment.darkBlue} w={'fit-content'}>
                  {t('not-found.go-to')}
                </BaseButton>
              </Link>
            </Stack>
            <Image
              src={'/images/not-found.png'}
              width={400}
              height={400}
              alt='not found'
            />
          </Flex>
        </Stack>
      </Container>
    </>
  );
};

export const getStaticProps: GetStaticProps = async (ctx) => {
  return {
    props: {
      ...(await serverSideTranslations(ctx.locale || ctx.defaultLocale!, [
        'main',
      ])),
    },
  };
};

export default NotFoundPage;
